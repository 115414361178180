import axios from 'axios';

export const request = async (baseURL, url, params = {}) => {
    const { headers = {} } = params;

    try {
        const response = await axios({
            baseURL,
            url: url,
            headers,
            ...params
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response.data.details) {
                console.log(error.response.data.details);
            }

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        throw error;
    }
};
