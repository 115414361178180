import * as pkg from '../package.json';

let _WSS_HOST = '';
let _DATA_BASE_URL = '';

(() => {
  const { hostname } = window.location;
  if (hostname === 'localhost' || new RegExp('([a-z-0-9]{2,63}).([a-z.]{2,5})$').exec(hostname) === null) {
    _DATA_BASE_URL = `http://${hostname}:8080/`;
    _WSS_HOST = 'wss://hrvn29vmvg.execute-api.us-east-1.amazonaws.com/Prod';
  } else if (hostname === 'displays.development.nikehyperlive.com') {
    _DATA_BASE_URL = 'https://api.development.nikehyperlive.com/';
    _WSS_HOST = 'wss://1cwj8i9uai.execute-api.us-east-1.amazonaws.com/Prod';
  } else if (hostname === 'displays.staging.nikehyperlive.com') {
    _DATA_BASE_URL = 'https://api.staging.nikehyperlive.com/';
    _WSS_HOST = 'wss://1cwj8i9uai.execute-api.us-east-1.amazonaws.com/Prod';
  } else if (hostname === 'displays.nikehyperlive.com') {
    _DATA_BASE_URL = 'https://api.nikehyperlive.com/';
    _WSS_HOST = 'wss://qyak8lou6e.execute-api.us-east-1.amazonaws.com/Prod';
  } else if (hostname === 'displays.sportpulse.test.nikecloud.com.cn') {
    _DATA_BASE_URL = 'https://api.sportpulse.test.nikecloud.com.cn/';
    _WSS_HOST = 'wss://qyak8lou6e.execute-api.us-east-1.amazonaws.com/Prod';
  } else if (hostname === 'displays.sportpulse.prod.nikecloud.com.cn') {
    _DATA_BASE_URL = 'https://api.sportpulse.prod.nikecloud.com.cn/';
    _WSS_HOST = 'wss://qyak8lou6e.execute-api.us-east-1.amazonaws.com/Prod';
  } else if (hostname === 'displays.sportpulse.nike.com.cn') {
    _DATA_BASE_URL = 'https://api.sportpulse.nike.com.cn/';
    _WSS_HOST = 'wss://qyak8lou6e.execute-api.us-east-1.amazonaws.com/Prod';
  }
})();

export const WSS_HOST = _WSS_HOST;
export const DATA_BASE_URL = _DATA_BASE_URL;
export const VERSION = pkg.version;
